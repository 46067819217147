import { useIntl } from 'gatsby-plugin-intl';
import React from 'react'
import FAQsLayout from '../../../../components/layout/FAQsLayout';
import SEO from '../../../../components/seo';
import { SITE_METADATA } from '../../../../utils/constants';
const TermsOfUse = () => {

const intl = useIntl();

  return (
    <FAQsLayout>
      <SEO
          lang={intl.locale}
          keywords={SITE_METADATA[process.env.GATSBY_PARTNER].keywords}
          title={SITE_METADATA[process.env.GATSBY_PARTNER].title}
          bodyClass={`${process.env.GATSBY_PARTNER}-${process.env.GATSBY_COUNTRY} ${process.env.GATSBY_PARTNER}-${process.env.GATSBY_COUNTRY}-${intl.locale}`}
        />
    </FAQsLayout>
  )
}

export default TermsOfUse;